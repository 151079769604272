import React from "react";

import './Footer.css'

import creditCardsIcon from '../../../utils/assets/footer/credit-cards-icon.png';
import docChatIcon from '../../../utils/assets/logo/savers-club-icon.png';
import emailIcon from '../../../utils/assets/home/icon/email-icon.png';
import customerServiceIcon  from '../../../utils/assets/home/icon/customer-service-icon.png'



const Footer = props => {
    const handleClick = ( sectionId ) => {
        sessionStorage.setItem('sectionName', sectionId);
        window.scroll(0, 0);
      };

      const handleGoTo = () =>{
        sessionStorage.setItem('sectionName', 'home');
        window.location.href = '/cancel-service';
      }
      
    return (
        <footer className="MainFooter__footer">

            <div className="MainFooter__main-footer-container">
                <div className="MainFooter__left-container" >
                    <div className="MainFooter__left-logo-container">
                        <img src={docChatIcon} alt="clubSaveIcon" className="MainFooter__logo" />
                    </div>
                    <div className="MainFooter__left-contact-container" >
                        <img src={customerServiceIcon} alt="customerSeviceIcon" className="MainFooter__icons" />
                        <p>(888) 447-4914</p>
                    </div>
                    <div className="MainFooter__left-email-container">
                        <img src={emailIcon} alt="emailIcon" className="MainFooter__icons" />
                        <p>
                        help@saversclubllc.com
                        </p>
                    </div>
                    <div>
                    <p className="MainFooter__business-address"> 
                    SAVERS CLUB LLC312 W 2nd St
Unit #A4815
Casper, WY 82601
                    </p>
                    </div>
                </div>

                <div className="MainFooter__menus-container">
                    <div className="MainFooter__center-container" >
                        <div className="MainFooter__center-menu-container">
                            <nav className="MainFooter__center-nav">
                                <ul>
                                    <li><a href="privacy-policy" onClick={() => handleClick('home')}>PRIVACY POLICY</a></li>
                                    <li><a href="refund-policy" onClick={() => handleClick('home')}>REFUND POLICY</a></li>
                                    <li><a href="terms-of-service" onClick={() => handleClick('home')}>TERMS OF SERVICE</a></li>
                                    <li><a href="cancel-service" onClick={() => handleClick('home')}>CANCEL SERVICE</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                   {/*  <div className="MainContainer__right-separator"></div> */}
                    <div className="MainFooter__right-container" >
                        <div className="MainFooter__right-menu-container">
                            <nav className="MainFooter__right-nav">
                                <ul >
                                    <li><a href="/" onClick={() => handleClick('home')}>HOME</a></li>
                                    <li><a href="about-us" onClick={() => handleClick('about-us')}>ABOUT US</a></li>
                                    <li><a href="price" onClick={() => handleClick('price')}>PRICE</a></li>
                                    <li><a href="contact-us" onClick={() => handleClick('contact-us')}>CONTACT</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            
            
            <div className="MainFooter__main-legal-description-container">
                <div className="MainFooter__legal-description-container">
                    <div className="MainFooter__legal-description">
                    <p>
                    Member, you will receive a text or email notification 48 hours prior to your 
                    monthly billing date. If you do not wish to proceed with your enrollment, you may call our 
                    Customer Service Department at (888) 447-4914 or click <a href= '/cancel-service' onClick={handleGoTo}>here</a> to cancel and submit your request.
                    </p>
                    </div>
                </div>
                <div className="MainFooter__right-and-cards-icon-container">
                    <div className="MainFooter__copy-right-container">
                        <p>
                            <strong>Savers Club © 2025 All Rights Reserved</strong>
                        </p>
                    </div>
                    <div className="MainFooter__card-icon-container">
                        <div className="MainFooter__credit-card-icons">
                            <img src={creditCardsIcon} alt="creditCardIcon" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;